import React from "react";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";

const AboutUs = () => {
  return (
    <div className="min-h-screen">
      <Header activeHeading={1} />
      <h1 className="text-2xl font-bold text-center mt-10">About Us</h1>
      <p className="text-center text-lg px-10 mt-5">
        Imagine a childhood filled with enchantment, where a fascination with
        jewellery transforms into a lifelong passion. This is my story, a
        journey born from my unwavering love for adornment. For me, jewellery
        was never just about adding sparkle, it was an art form that enhanced
        natural beauty and turned any ensemble into a masterpiece. A necklace
        could drape confidence around my shoulders, and earrings could ignite my
        smile with their vibrant energy. Jewellery became the key to unlocking
        my inner radiance and a passion waiting to be unleashed. Today, this
        love affair with adornment finds its expression in Taki. Each design is
        a silent vow, not just to dress you up, but to empower you to walk out
        the door feeling like the most luminous version of yourself. Taki is my
        invitation to you, to engage in the captivating ritual of
        self-adornment. It’s about discovering pieces that resonate with your
        unique style and celebrating the inherent beauty you already possess.
        Every Taki creation is crafted to blend seamlessly with your wardrobe,
        elevating your look from ordinary to extraordinary. My aspiration for
        Taki is to help you find the joy and confidence that comes with wearing
        beautiful jewellery. So come, explore the world of Taki, and unlock the
        timeless art of adorning yourself with empowerment and grace. The
        essence of Taki, my heart’s creation, is exactly this. Here’s to
        celebrating the beauty within each of us.
        <br />
        <br />
        With love,
        <br />
        Divyansshi Mendiratta,
        <br />
        Founder of Taki
      </p>
      <Footer />
    </div>
  );
};

export default AboutUs;
