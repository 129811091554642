import React from "react";
import { useNavigate } from "react-router-dom";
import { brandingData, categoriesData } from "../../../static/data";
import styles from "../../../styles/styles";

import { motion } from "framer-motion";
import { fadeIn } from "../../../utils/motion";
import SectionWrapper from "../../../hoc/SectionWrapper";

const Categories = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`${styles.section} hidden sm:block overflow-x-hidden`}>
        <div
          className={`branding my-12 flex justify-between w-full shadow-sm bg-white p-5 rounded-md`}
        >
          {brandingData &&
            brandingData.map((i, index) => (
              <motion.div
                variants={fadeIn("right", "spring", 0.5 * index, 0.75)}
                className="flex items-start overflow-hidden"
                key={index}
              >
                {i.icon}
                <div className="px-3">
                  <h3 className="font-bold text-sm md:text-base">{i.title}</h3>
                  <p className="text-xs md:text-sm">{i.Description}</p>
                </div>
              </motion.div>
            ))}
        </div>
      </div>

      <div
        className={`${styles.section} bg-white p-6 rounded-lg mb-12 overflow-x-hidden`}
        id="categories"
      >
        <div className="grid grid-cols-1 gap-[5px] md:grid-cols-2 md:gap-[10px] lg:grid-cols-4 lg:gap-[20px] xl:grid-cols-5 xl:gap-[30px]">
          {categoriesData &&
            categoriesData.map((i) => {
              console.log(i);
              const handleSubmit = (i) => {
                navigate(`/products?category=${i.title}`);
              };
              return (
                <motion.div
                  variants={fadeIn("left", "spring", 0.3 * i.id, 0.75)}
                  className="w-full h-[100px] flex items-center justify-between cursor-pointer overflow-hidden"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  <h5 className={`text-[18px] leading-[1.3]`}>{i.title}</h5>
                  <img
                    src={i.image_Url}
                    className="w-[120px] object-cover"
                    alt=""
                  />
                </motion.div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SectionWrapper(Categories, "category");
