import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";

import { motion } from "framer-motion";
import { textVariant } from "../../../utils/motion";
import SectionWrapper from "../../../hoc/SectionWrapper";

const FeaturedProduct = () => {
  const { allProducts } = useSelector((state) => state.products);

  return (
    <div>
      <div className={`${styles.section} overflow-hidden`}>
        <motion.div variants={textVariant()} className={`${styles.heading}`}>
          <h1>Featured Products</h1>
        </motion.div>
        <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12 border-0">
          {allProducts && allProducts.length !== 0 && (
            <>
              {allProducts &&
                allProducts.map((i, index) => (
                  <ProductCard data={i} key={index} index={index} side="left" />
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(FeaturedProduct, "featuredProducts");
