import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";

import Footer from "../components/Layout/Footer";
import BestSelling from "./BestSelling";
import SectionWrapper from "../hoc/SectionWrapper";

const BestSellingPage = () => {
  const { isLoading } = useSelector((state) => state.products);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header activeHeading={2} />
          <br />
          <br />
          <BestSelling />
          <Footer />
        </>
      )}
    </>
  );
};

export default SectionWrapper(BestSellingPage, "");
