const testimonials = [
  {
    index: 1,
    name: "Neelam Tiwari",
    comment:
      "I absolutely love my new necklace! The craftsmanship is exquisite, and it looks even more beautiful in person than in the pictures.",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 2,
    name: "Muskan Arora",
    comment:
      "I bought a pair of earrings for my wife's birthday, and she was thrilled! The quality is top-notch, and the design is elegant. Thank you!",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 3,
    name: "Shivani Raghav",
    comment:
      "I've received so many compliments on my bracelet since I started wearing it. It's a stunning piece, and I couldn't be happier with my purchase.",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 4,
    name: "Apporva Bohra",
    comment:
      "The ring I bought from your store is amazing! It's exactly what I was looking for, and the quality is outstanding. I'll definitely be recommending your jewelry to friends and family.",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 5,
    name: "Diksha Jeena",
    comment:
      "I'm so happy with my purchase! The necklace is beautiful, and it arrived quickly. I'll be browsing your store for more gifts in the future.",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 6,
    name: "Muskan Ghalot",
    comment:
      "The pendant I ordered is fantastic. It's exactly what I hoped for and looks great with everything. The shipping was fast too!",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 7,
    name: "Aysha Siddiqui",
    comment:
      "I couldn't be happier with the charm bracelet I bought. The details are stunning, and it's very well made. Highly recommend!",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
  {
    index: 8,
    name: "Namra",
    comment:
      "Wonderful customer service and the ring is perfect. It fits well and looks incredible. I'll definitely shop here again!",
    image:
      "https://images.squarespace-cdn.com/content/v1/5612e14be4b04e09dd389c0d/1b78bc52-d697-4964-bba1-9367a2940bf9/Seancc.jpg", // Path to the image file
  },
];

export default testimonials;
