import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import SectionWrapper from "../hoc/SectionWrapper";
import Footer from "../components/Layout/Footer";

const EventsPage = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={4} />
          <div className="my-3">
            <EventCard active={true} data={allEvents && allEvents[0]} />
          </div>
          <div className="mt-4">
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default SectionWrapper(EventsPage, "eventsPage");
