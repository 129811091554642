import React from "react";
import styles from "../../../styles/styles";
import { motion } from "framer-motion";
import { fadeIn, textVariant } from "../../../utils/motion";
import SectionWrapper from "../../../hoc/SectionWrapper";
import testimonials from "./testimonialsData";

import "./testimonials.css";

const Testimonials = () => {
  // duplicating the elements of the testimonials array to it works better with the css
  const duplicatedTestimonials = testimonials.concat(testimonials);

  return (
    <div className={`${styles.section} overflow-auto mb-4`}>
      {/* PREVIOUS CODE */}

      {/* <motion.div variants={textVariant()} className={`${styles.heading}`}>
        <h1>Testimonials</h1>
      </motion.div>
      <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
        {testimonials.slice(0, 5).map((testimonial, i) => (
          <motion.div
            variants={fadeIn("left", "spring", 0.5 * i, 0.75)}
            className="shadow-sm bg-[#fff] w-full h-[370px] rounded-lg"
            key={i}
          >
            <img
              src={testimonial.image}
              alt={testimonial.name}
              className="w-full h-[180px] object-cover rounded-t-lg mb-0"
            />
            <h2 className="text-xl text-[#f7d621] ml-4 mt-2">
              {testimonial.name}
            </h2>

            <p className="text-slate-500 mx-4 mt-2">
              {testimonial.comment.length > 120
                ? testimonial.comment.slice(0, 120) + "..."
                : testimonial.comment}
            </p>
          </motion.div>
        ))}
      </div> */}

      {/* New Code  */}

      <motion.div variants={textVariant()} className={`${styles.heading}`}>
        <h1>Testimonials</h1>
      </motion.div>
      <div className="slider rounded-lg mb-6">
        <div className="slide-track">
          {duplicatedTestimonials.map((testimonial, i) => (
            <div
              className="testimonial-background rounded-lg slide m-4 sm:w-[400px] h-[300px] w-[300px]"
              key={i}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <p className="text-lg font-Roboto text-slate-700">
                {testimonial.comment}
              </p>
              <br />
              <div className="flex justify-evenly gap-4">
                <div className="h-8 w-8 overflow-hidden rounded-full">
                  <img
                    className="h-full w-full object-cover"
                    src="avatar.png"
                    alt="Avatar"
                  />
                </div>
                <p className="text-xl font-Roboto text-slate-400">
                  {testimonial.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SectionWrapper(Testimonials, "testimonials");
