import React from "react";
import { motion } from "framer-motion";
import styles from "../../../styles/styles";
import { textVariant } from "../../../utils/motion";
import SectionWrapper from "../../../hoc/SectionWrapper";

const Instagram = () => {
  return (
    <div className={`${styles.section}`}>
      <motion.div variants={textVariant()} className={`${styles.heading}`}>
        <h1>Instagram</h1>

        <motion.div className="flex flex-wrap gap-6">
          <motion.div className="w-[20%]">
            <img
              src="https://cdn.pixabay.com/photo/2020/06/09/18/11/box-write-in-it-5279529_1280.jpg"
              alt=""
            />
          </motion.div>
          <motion.div className="w-[20%]">
            <img
              src="https://cdn.pixabay.com/photo/2020/06/09/18/11/box-write-in-it-5279529_1280.jpg"
              alt=""
            />
          </motion.div>
          <motion.div className="w-[20%]">
            <img
              src="https://cdn.pixabay.com/photo/2020/06/09/18/11/box-write-in-it-5279529_1280.jpg"
              alt=""
            />
          </motion.div>
          <motion.div className="w-[20%]">
            <img
              src="https://cdn.pixabay.com/photo/2020/06/09/18/11/box-write-in-it-5279529_1280.jpg"
              alt=""
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SectionWrapper(Instagram, "instagram");
