import React from "react";
import Header from "../components/Layout/Header";
import Hero from "../components/Route/Hero/Hero";
import Categories from "../components/Route/Categories/Categories";
import BestDeals from "../components/Route/BestDeals/BestDeals";
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct";
import Events from "../components/Events/Events";
import Sponsored from "../components/Route/Sponsored";
import Footer from "../components/Layout/Footer";
import BestSelling from "./BestSelling";
import SectionWrapper from "../hoc/SectionWrapper";
import Testimonials from "../components/AddOns/Testimonials/Testimonials";
import Discount from "../components/AddOns/Discount/Discount";
import TopNavSection from "../components/AddOns/TopNavSection/TopNavSection";
import Instagram from "../components/AddOns/Socials/Instagram";

const HomePage = () => {
  return (
    <div>
      <TopNavSection />
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      <BestDeals />
      <Events />
      <FeaturedProduct />
      <Sponsored />
      <BestSelling />
      <Discount />
      {/* <Instagram /> */}
      <Testimonials />
      <Footer />
    </div>
  );
};

export default SectionWrapper(HomePage, "homepage");
