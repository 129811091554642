import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const TandC = () => {
  return (
    <div>
      <Header activeHeading={1} />
      <h1 className="text-3xl font-bold text-center mt-10">
        Terms and Conditions
      </h1>
      <p className="text-center text-lg px-10 mt-5 mr-4 ml-4 mb-8 ">
        PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE CAREFULLY BEFORE
        USING THE WWW.TAKIBYDIVYANSSHI.COM WEBSITE. ANY FAILURE ON YOUR PART TO
        COMPLY WITH THESE TERMS AND CONDITIONS OF USE MAY LEAD TO THE
        CANCELLATION OF YOUR ACCOUNT AND RELATED PRIVILEGES. THEY ALSO CONTAIN
        IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
        OBLIGATIONS. Acceptance of Terms and Conditions of Use Welcome to
        www.takibydivyansshi.com, an interactive online service operated by
        Takibydivyansshi. These Terms and Conditions of Use apply to all
        visitors to and users of the Site, whether or not you have registered or
        subscribed for any of the products or services offered on the Site,
        including any mobile version thereof, or have contributed User Content,
        as defined below. By browsing or using the Site, you signify that you
        have read, understand and agree to be bound by these Terms and
        Conditions of Use. You also agree to be bound by these Terms and
        Conditions of Use, if you post www.takibydivyansshi.com links on your
        site, whether or not you are a registered member of
        www.takibydivyansshi.com We reserve the right, at our sole discretion,
        to change, modify, add, or delete portions of these Conditions at any
        time. If we do this, we will post the changes to these Terms and
        Conditions of Use on this page and will indicate at the top of this page
        the date these Conditions were last revised. Your continued use of the
        Site or Service after any such changes are posted constitutes your
        acceptance of the new Terms and Conditions of Use. If you do not agree
        to abide by these or any future Terms and Conditions of Use, do not
        continue to use or access the Site or Service. We will make reasonable
        efforts to notify users who have forwarded their email addresses to us
        of material changes to these Conditions by email, but it is your
        responsibility to regularly check the Site to determine if there have
        been changes to these Terms and Conditions of Use and to review such
        changes. Site Content and Intellectual Property “ Limited Licence of Use
        The Site and the items, information and materials it contains, including
        but not limited to designs, text, graphics, pictures, video,
        information, applications, software, music, sound and other files, and
        their selection and arrangement are offered to you on an “as is” basis
        by Takibydivyansshi, our sponsors, media partners and other third
        parties, make no warranties of any kind, either express or implied,
        including, without limitation, warranties of title or implied warranties
        of merchantability or fitness for a particular purpose, use,
        availability, validity, accuracy, reliability, timeliness, performance,
        completeness or suitability of the Site Content or as to the results
        ensuing from the use of the Site Content. Without limiting the
        generality of the foregoing, we make no representations about the
        comprehensive nature of the results of your searches conducted on the
        Site. It shall be your responsibility to ensure that any Site Content
        you access on and/or through this Site meet your specific requirements.
        YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOUR USE OF THIS SITE AND SITE
        CONTENT ARE AT YOUR OWN RISK. Takibydivyansshi, makes no representation
        related to the accuracy or reliability of, nor does it endorse, any
        opinion, declaration or other information published or distributed on
        the Site by any user, content provider or other person. Neither we nor
        our agents or representatives are responsible or liable in any manner
        for any User Content or Third Party Content posted on the Site or in
        connection with the Service, whether posted or caused by users of the
        Site, by www.takibydivyansshi.com, by third parties or by any of the
        equipment or programming associated with or utilised in the Site or the
        Service. Although we provide rules for user conduct and postings, we do
        not control and are not responsible for what users submit, post,
        transmit or share on the Site and are not responsible for any offensive,
        inappropriate, obscene, unlawful or otherwise objectionable content you
        may encounter on the Site or in connection with any User Content or
        Third Party Content. We are not responsible for the conduct, whether
        online or offline, of any user of the Site or Service. All messages and
        comments posted in blogs express the views of the author, and neither
        www.takibydivyanshi.com, nor Takibydivyansshi, will be held responsible
        for the content of any message. Takibydivyansshi reserves the right to
        modify, cancel or terminate any item on the Site, at any time, including
        the availability of any function, database or content. We may also
        impose limits on certain items or restrict the access to certain
        sections of the Site without prior notice and without incurring its
        liability. All of the Site Content is protected by copyright and belongs
        to Takibydivyansshi or third parties acting as content providers. No
        Site Content may be modified, copied, distributed, framed, reproduced,
        republished, downloaded, scraped, displayed, posted, transmitted, or
        sold in any form or by any means, in whole or in part, without our prior
        written permission, except that the foregoing does not apply to your own
        User Content that you legally post on the Site. You are granted a
        limited licence to access and use the Site and the Site Content and to
        download or print a copy of any portion of the Site Content to which you
        have properly gained access solely for your personal, non-commercial
        use. Such licence is subject to these Terms and Conditions of Use and
        does not permit use of any data mining, robots, scraping or similar data
        gathering or extraction methods. Except for your own User Content, you
        may not upload or republish Site Content on any Internet, Intranet or
        Extranet site or incorporate the information in any other database or
        compilation, and any other use of the Site Content is strictly
        prohibited. Unless explicitly stated herein, no Site Content and nothing
        in these Terms and Conditions of Use must be interpreted as giving you
        any licence or any rights whatsoever with regard to the intellectual
        property of Takibydivyansshi or of a third party acting as a content
        provider. Any use of the Site or the Site Content other than as
        specifically authorised herein, without our prior written permission, is
        strictly prohibited and will terminate the licence granted herein. Such
        unauthorised use may also violate applicable laws including copyright
        and trademark laws and applicable communications regulations and
        statutes. This licence is revocable at any time without notice and with
        or without cause. The trademarks and logos used or posted on the Site
        are trademarks and logos registered by Takibydivyansshi or third
        parties. Nothing on the Site may be interpreted in such a way as to
        allow, directly or indirectly, the use of such trademarks or logos
        without the prior written permission of their owner. Without limiting
        the generality of the foregoing, it is prohibited to reproduce the
        trademarks and logos displayed on the Site for commercial or advertising
        purposes or to increase the number of visitors to your site. Third Party
        Content and Links The Site contains hyperlinks and content, including
        but not limited to articles, photographs, text, graphics, pictures,
        designs, music, sound, video, information, and other items belonging to
        or originating from third parties (Third Party Content) or linking to
        third party websites (Third Party Sites). We do not investigate, monitor
        or check for accuracy, appropriateness, or completeness any Third Party
        Content, and we are not responsible for any Third Party Sites accessed
        through the Site or any Third Party Content posted on, available through
        or installed from the Site, including the content, accuracy,
        offensiveness, opinions, reliability, privacy practices or other
        policies of or contained in the Third Party Sites or the Third Party
        Content. The fact that Third Party Sites and Third Party Content are
        identified or accessible on the Site should not in any way be
        interpreted as an approval or endorsement by Takibydivyansshi and does
        not incur its liability in any way. If you decide to leave the Site and
        access the Third Party Sites or to use or install any Third Party
        Content, you do so at your own risk and you should be aware that our
        terms and policies no longer govern. You should review the applicable
        terms and policies, including privacy and data gathering practices, of
        any site to which you navigate from the Site or relating to any
        applications you use or install from the site. Limitation of Liability
        In no event will Takibydivyansshi and/or any third party providing Site
        Content on the Site, their related companies, directors, employees,
        representative or agents be liable to you or any third party or person
        for any direct, indirect, consequential, exemplary, incidental, special
        or punitive damages, including any lost income or lost data or damage to
        any computer system, arising from your use of the Site or the Service,
        or any of the Site Content or other materials on, or accessed through or
        downloaded from the Site, or errors or omissions contained therein, even
        if we are aware or have been advised of the possibility of such damages.
        The Site and the Service may be temporarily unavailable from time to
        time for maintenance or other reasons. We assume no responsibility for
        any error, omission, interruption, deletion, defect, delay in operation
        or transmission, communications line failure, theft or destruction or
        unauthorised access to, or alteration of, User communications. We are
        not responsible for any technical malfunction or other problems of any
        telephone network or service, computer systems, servers or providers,
        computer or mobile phone equipment, software, failure of email or
        players on account of technical problems or traffic congestion on the
        Internet or at any Site or combination thereof, including injury or
        damage to User’s or to any other person’s computer, mobile phone, or
        other hardware or software, related to or resulting from using or
        downloading materials in connection with the Web and/or in connection
        with the Site or the Service. Under no circumstances will we be
        responsible for any loss or damage, including any loss or damage to any
        User Content or personal injury or death, resulting from anyone’s use of
        the Site or the Service, or any User Content or Third Party Content
        submitted or posted on or through the Site or the Service or transmitted
        to Users, or any interactions between Users of the Site, whether online
        or offline. Termination We reserve the right, at our sole discretion, to
        limit or terminate access to the Site, delete your profile and any
        content or information that you have posted on the Site and/or prohibit
        you from using or accessing the Site or the Service (or any portion,
        aspect or feature of the Site or Service) for any reason, or no reason,
        at any time at our sole discretion, with or without notice. Without
        limiting the generality of the foregoing, we may at our sole discretion
        limit access to the Site and/or terminate the registration, subscription
        or membership of any User who violates these Terms and Conditions of Use
        or infringes any intellectual property rights of others. Cookies We
        employ the use of cookies. By accessing www.takibydivyansshi.com, you
        agreed to use cookies in agreement with Takibydivyansshi’s Privacy
        Policy. Most interactive websites use cookies to let us retrieve the
        user’s details for each visit. Cookies are used by our website to enable
        the functionality of certain areas to make it easier for people visiting
        our website. Some of our affiliate/advertising partners may also use
        cookies. Governing Law These Terms & Conditions with any separate
        agreements whereby we provide you Services shall be governed by and
        construed in accordance with the laws of India. Indemnity You agree to
        indemnify and hold Takibydivyansshi, its subsidiaries and affiliates,
        and each of their directors, officers, agents, contractors, partners and
        employees, harmless from and against any loss, liability, claim, demand,
        damages, costs and expenses, including reasonable attorney’s fees,
        arising out of or in connection with any User Content you submit, post
        or share on or through the Site, your use of the Site or Service, your
        conduct in connection with the Site or Service or with other users of
        the Site or Service, or any violation of these Terms and Conditions of
        Use or of any law or the rights of any third party. Entire Agreement and
        Severability These Terms and Conditions of Use constitute the entire
        agreement between you and us regarding the use of the Site and/or the
        Service, superseding any prior agreements between you and us relating to
        your use of the Site or the Service. Our failure to exercise or enforce
        any right or provision of these Terms and Conditions of Use shall not
        constitute a waiver of such right or provision in that or any other
        instance. If any provision of these Conditions are held invalid, the
        remainder of these Conditions shall continue in full force and effect.
        If any provision of these Terms and Conditions of Use shall be deemed
        unlawful, void or for any reason unenforceable, then that provision
        shall be deemed severable from these Conditions and shall not affect the
        validity and enforceability of any remaining provisions. Exchange Policy
        Details: Return Timing: You have 2 days from the date of delivery to
        initiate a return. Items must be returned in their original condition,
        unused, and with all tags and packaging intact. Return Process: To
        initiate a return, please contact our customer service team via email at
        [insert email address] or by phone at [insert phone number]. You will
        receive a return authorization and detailed instructions on how to send
        your item back to us. Taki Points: Once we receive and inspect your
        returned item, we will process your return. Instead of a direct refund,
        you will be credited with Taki Points in your profile. These points are
        time-limited and will expire 30 days from the date they are issued. Use
        of Taki Points: Taki Points can be used for future purchases on our
        website, providing you with flexibility and convenience. Please note
        that Taki Points are non-transferable and cannot be redeemed for cash.
        Exclusions: Customised items and items marked as final sale are not
        eligible for return or exchange. We advise you to review product
        descriptions carefully before making a purchase. Condition of Returned
        Items: Returned items must be in their original condition, unworn,
        unwashed, and with all original tags and packaging intact. Items that do
        not meet these criteria will not be accepted for return, and no Taki
        Points will be issued. Shipping Costs: Customers are responsible for
        return shipping costs unless the return is due to a defect or error on
        our part.
      </p>
      <Footer />
    </div>
  );
};

export default TandC;
