import React from "react";
import styles from "../../../styles/styles";
import { Link } from "react-router-dom";

const Discount = () => {
  return (
    <div className={`${styles.section} `}>
      <div className="bg-[#fff] w-full flex flex-wrap-reverse gap-10 rounded-lg shadow-sm">
        <div className="my-4 flex items-center justify-center mx-auto">
          <img
            src="https://res.cloudinary.com/dbwanc9og/image/upload/v1716693385/taaki/web-assets/All%20products/2nd%20product/2_i6fkiv.jpg"
            alt="Discount-img"
            className="w-full rounded-lg h-[320px] md:h-[360px] sm:h-[420px]"
          />
        </div>
        <div className="m-4 w-auto flex justify-center items-center mx-auto">
          <div className="w-full text-center p-4">
            <h2 className="text-3xl font-Roboto font-bold p-4">
              GET EXTRA 15% OFF!
            </h2>
            <p className="text-slate-600 font-medium font-Roboto">
              Use "AJJ15" discount code to get EXTRA 15% OFF on your purchase.
            </p>
            <p className="text-slate-600 font-medium font-Roboto">
              This code is valid for 1 hour only!
            </p>
            <Link to={"/products"}>
              <button className="uppercase font-Roboto font-medium bg-[#111] text-white m-4 p-4 hover:shadow-lg">
                Shop now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discount;
