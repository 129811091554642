import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import EventCard from "./EventCard";

import { motion } from "framer-motion";
import SectionWrapper from "../../hoc/SectionWrapper";
import { textVariant } from "../../utils/motion";

const Events = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);

  return (
    <div>
      {!isLoading && (
        <div className={`${styles.section}`}>
          <motion.div variants={textVariant()} className={`${styles.heading}`}>
            <h1>Popular Events</h1>
          </motion.div>

          <div className="w-full grid">
            {allEvents.length !== 0 && (
              <EventCard data={allEvents && allEvents[0]} />
            )}
            <h4>{allEvents?.length === 0 && ""}</h4>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionWrapper(Events, "event");
