import React, { useEffect, useState } from "react";
import styles from "../styles/styles";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";
import SectionWrapper from "../hoc/SectionWrapper";
import { textVariant } from "../utils/motion";

const BestSelling = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);
  useEffect(() => {
    const allProductsData = allProducts ? [...allProducts] : [];
    const sortedData = allProductsData?.sort((a, b) => b.sold_out - a.sold_out);
    setData(sortedData);
  }, [allProducts]);

  return (
    <div className={`${styles.section}`}>
      <motion.div variants={textVariant()} className={`${styles.heading}`}>
        <h1>Best Selling</h1>
      </motion.div>
      <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
        {data &&
          data.map((i, index) => (
            <ProductCard data={i} key={index} index={index} side="right" />
          ))}
      </div>
    </div>
  );
};

export default SectionWrapper(BestSelling, "");
