import React from "react";
import styles from "../../styles/styles";

import { motion } from "framer-motion";
import { zoomIn } from "../../utils/motion";
import SectionWrapper from "../../hoc/SectionWrapper";

const Sponsored = () => {
  return (
    <motion.div
      variants={zoomIn(0.5, 0.75)}
      className={`${styles.section} bg-white py-10 px-5 mb-12 cursor-pointer rounded-xl`}
    >
      <div className="flex flex-wrap gap-4 justify-between w-full">
        <div className="flex items-start">
          <img
            src="https://images-platform.99static.com//nQDl-g1lLzBffraHrWh768PyTxU=/459x630:1329x1500/fit-in/590x590/projects-files/37/3738/373819/1b52131e-44c8-4197-8d2f-b9af57a45700.jpg"
            alt=""
            style={{ width: "150px", objectFit: "contain" }}
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://images-workbench.99static.com/2VfkYYjGhdetvCqGqEnEeHNRShA=/99designs-contests-attachments/139/139839/attachment_139839030"
            style={{ width: "150px", objectFit: "contain" }}
            alt=""
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://images-workbench.99static.com/OUVVegZ37XxqRlcSnI9VruQT9XA=/99designs-contests-attachments/90/90900/attachment_90900732"
            style={{ width: "150px", objectFit: "contain" }}
            alt=""
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://images-workbench.99static.com/nUuSk1VNrKW7u9-o89QFQlrF50c=/99designs-contests-attachments/59/59241/attachment_59241250"
            style={{ width: "150px", objectFit: "contain" }}
            alt=""
          />
        </div>
        <div className="flex items-start">
          <img
            src="https://images-workbench.99static.com/8IYJEsaylAjhLvZ8p-qELB1KeJA=/99designs-contests-attachments/67/67848/attachment_67848488"
            style={{ width: "150px", objectFit: "contain" }}
            alt=""
          />
        </div>
      </div>
    </motion.div>
  );
};

export default SectionWrapper(Sponsored, "sponsored");
