import React, { useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";

import { motion } from "framer-motion";
import SectionWrapper from "../hoc/SectionWrapper";

const FAQPage = () => {
  return (
    <div>
      <Header activeHeading={5} />
      <Faq />
      <Footer />
    </div>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(null);

  const toggleTab = (index) => {
    setActiveTab(activeTab === index ? null : index);
  };

  return (
    <div className={`${styles.section} my-8`}>
      <h2 className="text-3xl font-bold text-gray-900 mb-8">FAQ</h2>
      <div className="mx-auto space-y-4">
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <div key={index} className="border-b border-gray-200 pb-4">
            <motion.button
              className="flex items-center justify-between w-full"
              onClick={() => toggleTab(index)}
              initial={false}
              animate={{
                backgroundColor:
                  activeTab === index ? "#edf2f7" : "transparent",
              }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="text-lg font-medium text-gray-900">
                {activeTab === index ? "▼ " : "► "}
                {index === 1 && "What is your return policy?"}
                {index === 2 && "How do I track my order?"}
                {index === 3 && "How do I contact customer support?"}
                {index === 4 && "Can I change or cancel my order?"}
                {index === 5 && "Do you offer international shipping?"}
                {index === 6 && "What payment methods do you accept?"}
              </span>
            </motion.button>
            {activeTab === index && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="mt-4"
              >
                <p className="text-base text-gray-500">
                  {index === 1 &&
                    "If you're not satisfied with your purchase, we accept returns within 30 days of delivery. To initiate a return, please email us at support@myecommercestore.com with your order number and a brief explanation of why you're returning the item."}
                  {index === 2 &&
                    "You can track your order by clicking the tracking link in your shipping confirmation email, or by logging into your account on our website and viewing the order details."}
                  {index === 3 &&
                    "You can contact our customer support team by emailing us at support@myecommercestore.com, or by calling us at (555) 123-4567 between the hours of 9am and 5pm EST, Monday through Friday."}
                  {index === 4 &&
                    "Unfortunately, once an order has been placed, we are not able to make changes or cancellations. If you no longer want the items you've ordered, you can return them for a refund within 30 days of delivery."}
                  {index === 5 &&
                    "Currently, we only offer shipping within the United States."}
                  {index === 6 &&
                    "We accept Visa, MasterCard, PayPal as payment methods. We also offer cash on delivery."}
                </p>
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionWrapper(FAQPage, "faq");
