import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";

import { motion } from "framer-motion";
import SectionWrapper from "../../../hoc/SectionWrapper";

const images = [
  "https://res.cloudinary.com/dbwanc9og/image/upload/v1716695258/taaki/web-assets/All%20products/20th/IMG_5686_ozazgh.jpg",
  "https://res.cloudinary.com/dbwanc9og/image/upload/v1716693314/taaki/web-assets/All%20products/10th%20product/IMG_5651_hbcxv3.jpg",
  "https://res.cloudinary.com/dbwanc9og/image/upload/v1716693371/taaki/web-assets/All%20products/27th/IMG_5610_nqekqy.jpg",
];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    // <div
    //   className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
    //   style={{
    //     backgroundImage:
    //       "url(https://media.istockphoto.com/id/486592060/photo/indian-jewellery.jpg?s=2048x2048&w=is&k=20&c=or886Zjs1uvAa5mbX5JksBiFK2Pd6k-p4vr3IBfsu_g=)",
    //   }}
    // >
    //   <div className={`${styles.section} w-[90%] 800px:w-[60%]`}>
    //     <h1
    //       className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3d3a3a] font-[600] capitalize`}
    //     >
    //       Best Collection from <br /> Taki By Divyansshi
    //     </h1>
    //     <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba]">
    //       Lorem ipsum dolor sit amet consectetur, adipisicing elit. Beatae,
    //       assumenda? Quisquam itaque <br /> exercitationem labore vel, dolore
    //       quidem asperiores, laudantium temporibus soluta optio consequatur{" "}
    //       <br /> aliquam deserunt officia. Dolorum saepe nulla provident.
    //     </p>
    //     <Link to="/products" className="inline-block">
    //       <div className={`${styles.button} mt-5`}>
    //         <span className="text-[#fff] font-[Poppins] text-[18px]">
    //           Shop Now
    //         </span>
    //       </div>
    //     </Link>
    //   </div>
    // </div>
    <div
      className={`relative min-h-[70vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}
    >
      {images.map((image, index) => (
        <motion.img
          key={index}
          src={image}
          alt={`background ${index}`}
          className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity ${
            index === currentImageIndex ? "opacity-100" : "opacity-0"
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: index === currentImageIndex ? 1 : 0 }}
          transition={{ duration: 1 }}
        />
      ))}
      <div className="absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2">
        <h1
          className={`text-[35px] leading-[1.2] 800px:text-[60px] text-[#3b3636] font-[600] capitalize ml-4 pl-4`}
        >
          Best Collection from <br /> Taki By Divyansshi
        </h1>
        <p className="pt-5 text-[16px] font-[Poppins] font-[400] text-[#000000ba] ml-4 pl-4">
          Taki isn't just about adornment; it's about becoming the conductor of
          your own vibe. Our jewellery is specially curated to give you endless
          ways to express your individual style. We're a fresh brand obsessed
          with unearthing timeless classics and sizzling trends. Think of us as
          your secret stylist, Feeling like a boss babe today? Rock our bold
          geometrics that scream power and personality. Or maybe you're
          channelling your inner free spirit? Delicate layers and whimsical
          accents await. Taki is more than just jewellery; it's a mood board for
          your life. Whether you’re celebrating a milestone, expressing your
          unique style, or simply treating yourself, Taki is here to accompany
          you on your journey of self-expression. So, it’s time to ditch the
          basics & embrace Taki. Explore our curated collections and discover
          the perfect pieces to harmonise with your unique style story. We can't
          wait to see what vibe you create next !
        </p>
        <Link to="/products" className="inline-block">
          <div className={`${styles.button} mt-5`}>
            <span className="text-[#fff] font-[Poppins] text-[18px]">
              Shop Now
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SectionWrapper(Hero, "hero");
